import React, { useState, useEffect } from 'react';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';
import { db } from '../backEnd/firebase';
import '../Styles/general.css';
import { useNavigate } from 'react-router-dom';
import OwnerLogin from './OwnerLogin'; // Import the OwnerLogin component
import image2 from '../images/image8.png';
import image5 from '../images/image1.png';
import jobdash from '../images/jobdash.png';
import Newjob from '../images/NewJob.png';
import JobSum from '../images/jobSumarry.png';
import chat from '../images/chat.png';
import invent1 from '../images/invent1.png';
import invent2 from '../images/invent2.png';
import logo from '../images/logo.png';
import webs from '../images/webs.png';
import inventDash from '../images/inventDash.png';
import pictuse from '../images/pictuse.jpeg';
import tech from '../images/tech.png';
function Welcome() {
    const [form, setForm] = useState({

        fullname: '',
        email: '',
        phone: '',
        companyName: '',
        queryType: '',
        message: '',

    });
    const [formData, setFormData] = useState({
        companyName: "",
        email: "",
        phone: "",
        message: "",
      });
    const [consent, setConsent] = useState(false);
    const [existingCompanyNames, setExistingCompanyNames] = useState([]);
    const navigate = useNavigate();

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia',
        'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ];
    const companySizes = ['1-10', '11-30', '31-60', '61-100', '100+'];

    useEffect(() => {
        const fetchExistingCompanyNames = async () => {
            const snapshot = await getDocs(collection(db, 'Clients'));
            const companyNames = snapshot.docs.map(doc => doc.data().companyName);
            setExistingCompanyNames(companyNames);
        };

        fetchExistingCompanyNames();
    }, []);

    const generateCompanyCode = () => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';
        let code;

        do {
            const randomLetters = Array.from({ length: 3 }, () => letters[Math.floor(Math.random() * letters.length)]).join('');
            const randomNumbers = Array.from({ length: 3 }, () => numbers[Math.floor(Math.random() * numbers.length)]).join('');
            code = `${randomLetters}${randomNumbers}`;
        } while (existingCompanyNames.includes(code));

        return code;
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleConsentChange = (e) => {
        setConsent(e.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!consent) {
            alert('Please agree to receive email communications before submitting.');
            return;
        }

        if (existingCompanyNames.includes(form.companyName)) {
            alert('This company name already exists. Please use a different name.');
            return;
        }

        if (form.password !== form.confirmPassword) {
            alert('Passwords do not match. Please check and try again.');
            return;
        }

        const uniqueCompanyCode = generateCompanyCode();
        const currentDate = new Date().toISOString();

        try {
            const docRef = doc(db, 'Clients', form.companyName); // Create a document reference with `email` as ID
            await setDoc(docRef, {
                ...form,
                date: currentDate,
                companyCode: uniqueCompanyCode
            });
            alert('Registration successful!');
            setForm({

                fullname: '',
                email: '',
                phone: '',
                companyName: '',
                queryType: '',
                message: '',

            });
            setConsent(false);
        } catch (error) {
            console.error('Error adding document:', error);
            alert('Error submitting form. Please try again.');
        }
    };
    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit2 = (e) => {
        e.preventDefault();
        console.log("Form submitted:", formData);
        alert("Thank you! We will contact you by phone within 24 hours.");
        setFormData({
          companyName: "",
          email: "",
          phone: "",
          message: "",
        });
      };
    const createNewUser = () => {
        // Get the first element with the class 'schedule'
        const formSection = document.getElementsByClassName('schedule')[0];

        // Check if the element exists before trying to scroll
        if (formSection) {
            formSection.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const handleLogin = () => {
        navigate('OwnerLogin');
    };
    const handleCreateNewUser = () => {
        navigate('/UserForm');
    };
    const handleDownload = () => {
        // Path to the file in the public folder
        const fileUrl = "/TechTrack.apk"; // Correct file path
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "TechTrack.apk"; // Name of the file when downloaded
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };





    return (
        <div className="MainPage">
            <div >
                <img src={logo} alt="logo" className="logo" />
            </div>

            <div className="welcome">
                <div className='leftSide'>
                    <div className='leftText'>
                        Empowering Small Businesses Technologies
                        with Web & Mobile Solutions
                       
                     
                    </div>

                </div>
                <div className='rightSide'>

                    <div className="Imageweb">
                        <header> Affordable Website & Data Storage</header>
                        <div className='title'>For Small Businesses
                            Just $200! Satisfaction Guaranteed or 100% Money Back.</div>
                        <img src={webs} alt=" 1" className="webs" />
                        <p><strong>Simple, Fast, and Affordable:</strong> </p>

                        <div className="package-table-container">



                            <table className="package-table">
                                <thead>
                                    <tr>
                                        <th>Package</th>
                                        <th>Price</th>
                                        <th>Features</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="starter-package">
                                        <td>Starter Package</td>
                                        <td>$200</td>
                                        <td>Website creation + Firebase storage setup.</td>
                                    </tr>
                                    <tr className="plus-package">
                                        <td>Plus Package</td>
                                        <td>$300</td>
                                        <td>Starter + Social Media Integration + Business Email Setup.</td>
                                    </tr>
                                    <tr className="pro-package">
                                        <td>Pro Package</td>
                                        <td>$400</td>
                                        <td>Plus + Google Business Profile + Additional customizable website feature.</td>
                                    </tr>
                                </tbody>
                               
                            </table>
                            <button className="cta-button" onClick={createNewUser}>Request a Sample for your Business</button>
                        </div>


                    </div>

                    <div className="Imageweb2">
                        <header> Techtrack Suite Applications</header>
                        <div className='title'>The  Solution for Warehouse Management and Work Tracking
                            – Enjoy a 30-Day Free Trial!</div>

                        <div className="Images">

                            <img src={image5} alt="Description of Picture 1" className="Images1" />
                            <img src={image2} alt="Description of Picture 1" className="Images2" />

                        </div>
                        <p>
                            Designed for{" "}
                            <strong>cable company contractors, HVAC technicians, electricians, painters, landscapers</strong>, and other construction or maintenance
                            professionals.

                        </p> <img src={invent1} alt="Equipment Inventory" className="boxImage" /><br></br>
                        It addresses the unique operational challenges faced by<strong>technicians, warehouse managers</strong>, and <strong>corporate teams</strong>.
                    </div>


                </div>

            </div>
            <section className="techtrack">


                <div className="solutions">
                    <h2>Web Solutions</h2>

                    <p>
                        Get your website ready in just <strong>3 days</strong>. With our
                        <strong> 100% satisfaction guarantee</strong> or a full <strong>money-back guarantee</strong>,
                        you can trust us to deliver professional results tailored to your needs.
                    </p>
                    <button className="cta-button" onClick={createNewUser}>Get Started Now</button>
                </div>
                <div className="solutions">
                    <h2>TechTrack Suite</h2>
                    <p>
                        A global solution designed for companies with <strong>field technicians</strong>
                        and <strong>warehouse equipment management</strong>. TechTrack Suite helps
                        track jobs, manage equipment, and optimize operations for enhanced efficiency.
                    </p>
                </div>
            </section>
            <div className="welcome2">



                <div className="leftSide3">

                    <div>
                        <img src={pictuse} alt="Description of Picture 1" className="Images12" />
                    </div>
                    <div>
                        <h3>what you can get from 200$</h3>
                        <ul>
                            <li><strong>Responsive Website:</strong> Fully functional and mobile-friendly.</li>
                            <li><strong>Up to 5 Pages:</strong> Includes Home, About, Services, Contact, and one custom page.</li>
                            <li><strong>Basic SEO:</strong> To help your site get found online.</li>
                            <li>
                                <strong> Firebase Cloud Storage </strong>Integration (Optional): Data storage solutions using Firebase.
                            </li>
                            <li><strong>Social Media </strong>Integration: Connect your social media links or feeds.</li>
                        </ul>

                        <ul>
                            <li>
                                <strong>Simple, Fast, and Affordable:</strong> We offer a streamlined process to get your business online without breaking the bank.
                            </li>
                            <li>
                                <strong>Satisfaction Guaranteed:</strong> We provide a satisfaction guarantee with a refund or revision policy.
                            </li>
                            <li>
                                <strong>Tailored Solutions:</strong> Websites are customized to meet your unique business needs.
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <strong>What if I need more than 5 pages?</strong> – Additional pages can be added for a small fee.
                            </li>
                            <li>
                                <strong>Can I add e-commerce later?</strong> – Yes, we can expand your website to include e-commerce.
                            </li>
                            <li>
                                <strong>How long will it take to complete my website?</strong> – Typically 3–5 business days.
                            </li>
                        </ul>

                        <h3>How It Works</h3>
                        <ul>
                            <li>
                                <strong>Step 1: Reach Out</strong> – Contact us with your website requirements.
                            </li>
                            <li>
                                <strong>Step 2: Customization</strong> – We’ll design a website tailored to your business goals.
                            </li>
                            <li>
                                <strong>Step 3: Review and Revise</strong> – Review the website, and we’ll make any necessary adjustments.
                            </li>
                            <li>
                                <strong>Step 4: Launch</strong> – Once approved, we’ll launch your website.
                            </li>
                            <li>
                                <strong>Step 5: Ongoing Support</strong> – We provide support for future needs.
                            </li>
                        </ul>
                    </div>
                </div>



                <div className="leftSide31">
                    <div>
                        <img src={tech} alt="Description of Picture 1" className="Images13" />
                    </div>
                    <div className='techText'>
                        <section className="techtrack-overview">
                            <div className="headline">
                                <h3>Why TechTrack Suite is the Best ?</h3>
                                <p>
                                    This seamless and efficient workflow solution is more than just an app—it’s a productivity-enhancing platform designed to:
                                </p>
                                <ul>
                                    <li>Empower Technicians</li>
                                    <li>Optimize Warehouse Operations</li>
                                    <li>Provide Managers with Actionable Insights</li>
                                </ul>
                                <p>
                                    Current corporate applications provided to contractors
                                    such as TechMobile for Spectrum, Cox App, or Xfinity App offer limited functionalities, leaving critical gaps in areas like job tracking, equipment management, and communication systems.
                                </p>
                                <p>
                                    <strong>TechTrack Suite</strong> bridges these gaps with a fully integrated platform that streamlines:
                                </p>
                                <ul>
                                    <li>Job Recording</li>
                                    <li>Inventory Management</li>
                                    <li>Equipment Tracking</li>
                                    <li>Real-Time Communication</li>
                                </ul>
                                <h4>
                                    From Mobile Applications to Web Dashboards and Windows-Based Warehouse Inventory Management
                                </h4>
                            </div>
                            <div className="why-love-techtrack">

                                <p>
                                    With <strong>TechTrack Suite</strong>, cable company contractors and other professionals can save time, reduce costs, and enhance operational effectiveness. It’s the ultimate tool for modern workforce management.
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>


            <div className="welcome2">
                <div className='leftSide32'>



                    <div>
                        <form id="schedule" className="schedule" onSubmit={handleSubmit}>
                            <div className='Formfill'> Fill out the form below to request a service, receive a quote,
                                or obtain additional information, simply
                                .</div>
                            <fieldset>

                                <legend>Contact Information</legend>
                                <label>
                                    * Full Name:
                                    <input type="text" name="fullname" value={form.fullname} onChange={handleChange} required />
                                </label>
                                <label>
                                    * Email Address:
                                    <input type="email" name="email" value={form.email} onChange={handleChange} required />
                                </label>
                                <label>
                                    * Phone Number:
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={form.phone}
                                        onChange={handleChange}
                                        required
                                        placeholder="e.g., +1 980 123 4567"
                                        pattern="(\+1\s?\d{10}|\+?\d{1,2}\s?\(?\d{3}\)?\s?\d{3}[-\s]?\d{4}|\d{3}[-\s]?\d{3}[-\s]?\d{4})"
                                        title="Phone number must be valid."
                                    />
                                </label>
                            </fieldset>
                            <fieldset>
                                <legend>Company Information</legend>
                                <label>
                                    * Company Name:
                                    <input type="text" name="companyName" value={form.companyName} onChange={handleChange} required />
                                </label>
                                <label>
                                    Query Type:
                                    <select name="queryType" value={form.queryType} onChange={handleChange} required>
                                        <option value="" disabled>Choose an option</option>
                                        <option value="quote">Request a Quote</option>
                                        <option value="general">General Query</option>
                                        <option value="support">Customer Support</option>
                                    </select>
                                </label>
                                <label>
                                    Message:
                                    <textarea
                                        name="message"
                                        rows="5"
                                        value={form.message}
                                        onChange={handleChange}
                                        placeholder="Write your message here"
                                        required
                                    />
                                </label>
                            </fieldset>
                            <label className="consent">
                                <input type="checkbox" checked={consent} onChange={handleConsentChange} required />
                                I agree to receive email communications and understand I can opt out at any time.
                            </label>
                            <button type="submit">Submit</button>
                        </form>
                    </div>

                </div>
                <div className='rightSide2'>
                    <div class="download-section">
                        <h2>Download TechtrackSuite Application</h2>
                        <div className="pricingBox">
                            <h3>Pricing and Free Trial</h3>
                            <ul>
                                <li><strong>60 Days Free Trial</strong>: Try TechTrack risk-free!</li>
                                <li><strong>Flexible Pricing:</strong></li>
                                <ul>
                                    <li><strong>$10/month per technician</strong> for companies with 10 or more technicians (includes all features).</li>
                                    <li>
                                        The <strong>Techtrack Warehouse app</strong> integrates seamlessly with the <strong>Warehouse Inventory Application </strong>, enabling real-time synchronization for efficient equipment management:
                                        <ul>
                                            <li>Technicians receive real-time updates on all equipment received.</li>
                                            <li>The app displays equipment returned to the warehouse directly.</li>
                                            <li>
                                                Warehouse inventory is automatically updated with information about:
                                                <ul>
                                                    <li>Equipment installed at client locations by technicians.</li>
                                                    <li>Equipment transfers between technicians.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>
                                    This suite includes all associated mobile applications, website applications, and desktop Windows Application for
                                    inventory management.
                                </p>
                                <p>Elevate productivity and collaboration with one powerful, user-friendly tool.</p>

                                <li><strong>Free Cancellation:</strong> No obligations after the trial period.</li>
                            </ul>
                        </div>


                        <div class="version-boxes">
                            <div class="version-box2">
                                <h2>Android</h2>
                                <h3>Field Technician Application</h3>
                                <p>Download the APK version for Android devices.</p>
                                <button onClick={handleDownload}>TechTrack Android</button>
                            </div>
                            <div class="version-box2">
                                <h2>iOS</h2>
                                <h3>Field Technician Application</h3>
                                <p>Download the app for iOS devices.</p>
                                <button>Download iOS App</button>
                            </div>
                            <div class="version-box2">
                                <h2>Windows</h2>
                                <h3>Warehouse Equipment Management</h3>
                                <p>Download the EXE version for Windows desktop.</p>
                                <button><a href="/TechTrackSetup.7z" download>Warehouse  Windows Pc  </a></button>
                            </div>
                        </div>


                    </div>


                </div>


            </div>

            <div className="whyTechTrack">

                <div>
                    <p>* * *</p>

                    <div className="fourBoxes">
                        <div className='leftBox'>

                            <div className='benefits'> Techtrack Mobile Easy to Train On & Use, designed with field techs in mind</div>
                            <div className='techboxes'>
                                <div className="pricingBox2 text">
                                    <strong>TEchTrack is easy to use, requiring minimal auto training </strong><br></br>
                                    Field technicians love using Techtrack because it makes it faster and easier to
                                    complete their work and  eliminates paperwork</div>
                                <img src={JobSum} alt="Equipment Inventory" className="boxImage" />
                                <img src={Newjob} alt="Equipment Inventory" className="boxImage" />
                                <img src={jobdash} alt="Equipment Inventory" className="boxImage" />
                                <img src={inventDash} alt="Equipment Inventory" className="boxImage" />
                                <img src={chat} alt="Equipment Inventory" className="boxImage" />

                                <div className='benefits'> Easy to Use and Benefits Warehouse</div>
                                <div className="pricingBox2 text">
                                    <strong>TechTrack Warehouse, TechTrack Web Dashboard, and TechTrack Mobile all
                                        operate on a unified data system</strong><br></br>Seamlessly connecting field technicians
                                    using mobile apps, warehouse staff working from desktop environments,
                                    and managers accessing the web dashboard. This shared, real-time data ensures
                                    that everyone stays aligned and informed,
                                    fostering collaboration and eliminating discrepancies across the team.
                                </div>
                                <img src={invent1} alt="Equipment Inventory" className="boxImage2" />
                                <img src={invent2} alt="Equipment Inventory" className="boxImage2" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='pricingBox' >

                    Are you a company or an individual with technical needs? Test the application
                    today by filling out the form <a href=".#schedule"> Create Free Account</a> get the user manual. <br></br>
                    Enjoy 24/7 assistance during training and throughout your 30-day free trial!

                </div>
            </div>
            <div className='challengeSol'>


                <section className="challenges">
                    <h3>Challenges in the Current System</h3>

                    <h4>Technician Work Tracking</h4>
                    <ul>
                        <li>
                            <strong>Limited Access to Job Records:</strong> Once a work order is
                            completed and saved in the corporate application, technicians lose
                            access to detailed records of past jobs.
                        </li>
                        <li>
                            <strong>Manual Note-Keeping:</strong> Contractors often resort to
                            handwritten notes to track completed jobs and estimate weekly or
                            bi-weekly pay. This is time-consuming and prone to errors.
                        </li>
                    </ul>

                    <h4>Equipment Inventory Management</h4>
                    <ul>
                        <li>
                            <strong>Inaccurate Inventory Data:</strong> Corporate applications only
                            show the status of uninstalled equipment, leading to issues like
                            misplaced or misallocated equipment.
                        </li>
                        <li>
                            <strong>Outdated Warehouse Management:</strong> Many warehouses rely on
                            Excel sheets for equipment assignments and returns, which lacks
                            real-time updates and accuracy.
                        </li>
                    </ul>

                    <h4>Communication Gaps</h4>
                    <ul>
                        <li>
                            Companies often rely on third-party applications for communication, leading
                            to inefficiencies and scattered information.
                        </li></ul>
                </section>

                <section className='solution'>
                    <h3>Solutions Techtrack suite brings</h3>

                    <h4>For Technicians</h4>
                    <ul>
                        <li>
                            <strong>Streamlined Job Tracking: </strong>


                            Quickly record job completion details, including job type, estimated
                            pay, and equipment used, in just 30 seconds.

                            Instantly generate a daily summary displaying the number of jobs
                            completed and the total estimated pay.


                        </li>
                        <li>
                            <strong>Accessible Job History:</strong> Technicians can access a
                            detailed history of all completed jobs, eliminating the need for manual
                            notes.
                        </li>
                        <li>
                            <strong>Efficiency in Note-Taking:</strong> The mobile app saves
                            significant time by automating job tracking and pay estimation, allowing
                            technicians to focus on their core tasks.
                        </li>
                    </ul>

                    <h4>For Warehouse Managers</h4>
                    <ul>
                        <li>
                            <strong>Real-Time Inventory Updates:</strong> Automatically update
                            inventory with every job, linking equipment serial numbers to job numbers
                            and technician IDs.
                        </li>
                        <li>
                            <strong>Enhanced Equipment Tracking:</strong> Search and filter equipment
                            transactions to view detailed movements, including transfers between
                            technicians or installations.
                        </li>
                    </ul>

                    <h4>For Managers and Owners</h4>
                    <ul>
                        <li>
                            <strong>Real-Time Insights:</strong> Access comprehensive reports on job
                            completions, equipment usage, and technician performance in real-time.
                        </li>
                        <li>
                            <strong>Improved Accountability:</strong> Ensure accurate tracking of
                            equipment and job details without relying solely on corporate
                            applications.
                        </li>
                    </ul>

                    <h4>Integrated Communication</h4>
                    <ul>
                        <li>
                            <strong>Built-In Messaging:</strong> An instant chat feature allows
                            technicians, managers, and warehouse staff to communicate and share
                            information without using external apps.
                        </li>
                    </ul>
                </section>

                <section className="avantage">
                    <h4>TechTrackSuite Key Advantages </h4>

                    <h4>Time-Saving Efficiency</h4>
                    <ul>
                        <li>Reduces time spent on manual note-keeping and inventory updates.</li>
                        <li>Automates job tracking and pay estimation for technicians.</li>
                    </ul>

                    <h4>Accuracy and Transparency</h4>
                    <ul>
                        <li>
                            Ensures precise tracking of job completions and equipment movements.
                        </li>
                        <li>
                            Provides real-time data, reducing errors and discrepancies in inventory
                            management.
                        </li>
                    </ul>

                    <h4>Enhanced Productivity</h4>
                    <ul>
                        <li>
                            Enables technicians to focus on their tasks by automating administrative
                            burdens.
                        </li>
                        <li>
                            Allows managers to make informed decisions based on real-time data.
                        </li>
                    </ul>

                    <h4>Improved Communication</h4>
                    <ul>
                        <li>
                            Integrated messaging fosters better collaboration and quicker problem
                            resolution.
                        </li>
                    </ul>


                </section>
            </div>
            <div className="footer">
                <p>&copy; {new Date().getFullYear()} TechTrack Suite. All Rights Reserved.</p>

                <p><strong>Contact:</strong> <a href="mailto:support@techtrack.com">support@techtrack.com</a></p>
                <p>Charlotte, North Carolina, USA 3244 Banbaurg Court, 28216 USA</p>
                <p>&copy; <span id="year"></span> TechTrack Suite. All rights reserved.</p>
                <p>Any usage, modification, or distribution of TechTrack and its suite of solutions without prior written approval from the Lead Tech Innovator is strictly prohibited.</p>
                <p>For permissions or inquiries, please contact: <strong>Owner:</strong> Papy Mulongo
                    <strong>Lead Tech Innovator:</strong> <a href="mailto:papayusmail@gmail.com">papayusmail@gmail.com</a>
                    <strong>Support:</strong> <a href="mailto:support@techtracsuite.com">support@techtracsuite.com</a></p>

                <ul className="footer-links">
                    <li><a href="/terms">Terms of Service</a></li>
                    <li><a href="/privacy">Privacy Policy</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                </ul>

            </div>





        </div>
    );
}

export default Welcome;
